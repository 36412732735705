import { Form, Outlet } from '@remix-run/react'
import { atom, useAtom } from 'jotai'

import { Button } from '~/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetTrigger
} from '~/components/ui/sheet'
import { Icon } from '../ui/icon'
import { ScrollArea } from '../ui/scroll-area'
import { SidebarProvider } from '../ui/sidebar'
import { Sidebar } from './sidebar'

export function Logo(props: { className?: string }) {
  return <div className="text-xl p-2 text-gray-600"><img src="https://biescode.com/biescode-logo.svg" alt="logo" className="h-10 invert-100 grayscale-100 mb-2" /> </div>
}

export const sidebarOpenAtom = atom(false)

function HamburgerMenu(props: React.PropsWithChildren<{}>) {
  const [open, setOpen] = useAtom(sidebarOpenAtom)

  return (
    <Sheet modal open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent
        side="left"
        className=" w-[280px] p-0 data-[state=closed]:duration-100 data-[state=open]:duration-100"
      >
        <div className="h-16 border-b pt-1">
          <Logo className="ml-4 inline-flex h-14" />
        </div>
        <ScrollArea className="h-full">
          <Sidebar
          // className="hidden lg:block"
          />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}

export function Layout(props: React.PropsWithChildren<{}>) {
  return (
    <div className="flex min-h-screen flex-col">
      <div className="h-16 border-b pt-1 print:hidden">
        <div className="flex flex-row justify-between">
          <HamburgerMenu>
            <Button variant="ghost" className="hover:bg-transparent md:hidden">
              <Icon name="lucide:menu" className="size-9" />
            </Button>
          </HamburgerMenu>
          <Logo className="ml-0 inline-flex h-10 md:ml-4" />
          <Form method="post" action="/logout" className="flex items-center mr-4">
            <Button variant="secondary" type="submit" className="flex items-center gap-2 hover:bg-gray-800 hover:text-white">
              <Icon name="hugeicons:logout-05" className="size-6" /> Oturumu Kapat
            </Button>
          </Form>
        </div>
      </div>
      <div className="grid grow md:grid-cols-[var(--sidebar-width)_auto_auto_auto_auto]">
        <SidebarProvider>
          <Sidebar className="col-span-1 hidden h-[calc(100vh-theme('spacing.12'))] overflow-auto border-r md:block print:hidden" />
        </SidebarProvider>
        <div className="col-span-4  h-[calc(100vh-theme('spacing.12'))] w-[100vw] overflow-auto bg-slate-50 md:w-[calc(100vw-var(--sidebar-width))] print:h-auto">
          <div className="px-4 pt-4 lg:px-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
